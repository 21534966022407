<div [formGroup]="formGroup">
  <div class="row">
    <div class="form-group col-12 col-lg-8">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="street"
          data-cy="street"
          formControlName="street"
          [placeholder]="'LABEL.STREET' | translate"
        />
        <label for="street">{{ 'LABEL.STREET' | translate }}</label>
        <nv-error
          id="street-error-required"
          data-cy="street-error-required"
          *ngIf="formGroup.get('street').errors?.required && formGroup.get('street').touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
      </div>
    </div>
    <div class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="housenumber"
          data-cy="house-number"
          formControlName="housenumber"
          [placeholder]="'LABEL.HOUSENUMBER' | translate"
        />
        <label for="housenumber">{{ 'LABEL.HOUSENUMBER' | translate }}</label>
        <nv-error
          id="housenumber-error-required"
          data-cy="housenumber-error-required"
          *ngIf="formGroup.get('housenumber').errors?.required && formGroup.get('housenumber').touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="zip"
          data-cy="zip"
          min="4"
          max="5"
          formControlName="zip"
          autocomplete="postal-code"
          [placeholder]="'LABEL.POSTAL_CODE' | translate"
        />
        <label for="zip">{{ 'LABEL.POSTAL_CODE' | translate }}</label>
        <nv-error
          id="zip-error-required"
          data-cy="zip-error-required"
          *ngIf="formGroup.get('zip').errors?.required && formGroup.get('zip').touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
        <nv-error
          id="zip-error-invalid"
          data-cy="zip-error-invalid"
          *ngIf="formGroup.get('zip').errors?.pattern && formGroup.get('zip').touched"
          >{{ 'ERROR.PATTERN_INVALID' | translate }}</nv-error
        >
      </div>
    </div>
    <div class="form-group col-12 col-lg-4">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="city"
          data-cy="city"
          formControlName="city"
          autocomplete="address-level2"
          [placeholder]="'LABEL.CITY' | translate"
        />
        <label for="city">{{ 'LABEL.CITY' | translate }}</label>
        <nv-error
          id="city-error-required"
          data-cy="city-error-required"
          *ngIf="formGroup.get('city').errors?.required && formGroup.get('city').touched"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
        <nv-error
          id="city-error-invalid"
          data-cy="city-error-invalid"
          *ngIf="formGroup.get('city').errors?.pattern && formGroup.get('city').touched"
          >{{ 'ERROR.PATTERN_INVALID' | translate }}</nv-error
        >
      </div>
    </div>

    <div class="form-group col-lg-4">
      <div class="form-floating">
        <nv-auto-complete
          id="country"
          formControlName="country"
          [options]="countries | async"
          [readOnly]="!config.countryEditable"
          [placeholder]="'LABEL.COUNTRY' | translate"
        >
        </nv-auto-complete>
        <nv-form-info-container *ngIf="!config.countryEditable">
          <nv-info-modal>
            <p>{{ 'APPLICATION.PAGE.PERSONAL_DATA.ADDRESS.COUNTRY.INFO' | translate }}</p>
          </nv-info-modal>
        </nv-form-info-container>
        <nv-error
          *ngIf="formGroup.get('country').errors?.required && formGroup.get('country').touched"
          id="country-error-required"
          data-cy="country-error-required"
          >{{ 'ERROR.REQUIRED' | translate }}</nv-error
        >
        <nv-error
          *ngIf="formGroup.get('country').errors?.minlength && formGroup.get('country').touched"
          id="country-error-pattern"
          data-cy="country-error-pattern"
          >{{ 'ERROR.PATTERN_MIN' | translate }}</nv-error
        >
        <nv-error
          *ngIf="formGroup.get('country').errors?.invalidOption && formGroup.get('country').touched"
          id="country-error-country"
          data-cy="country-error-country"
          >{{ 'APPLICATION.PAGE.PERSONAL_DATA.PERSONAL.ERROR.COUNTRY' | translate }}</nv-error
        >
      </div>
    </div>
  </div>
</div>
